/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:7a2f53d5-bd35-4e40-a59d-b3ef9c3d6488",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_1UKtZWzOs",
    "aws_user_pools_web_client_id": "4qv347b56bq7iad1sh5oluhuge",
    "oauth": {},
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "seam-s3-upload-0100845-dev",
    "aws_user_files_s3_bucket_region": "us-east-1",
    "aws_content_delivery_bucket": "s3uploaderui-20220624001405-hostingbucket-dev",
    "aws_content_delivery_bucket_region": "us-east-1",
    "aws_content_delivery_url": "http://s3uploaderui-20220624001405-hostingbucket-dev.s3-website-us-east-1.amazonaws.com"
};


export default awsmobile;
